export default {
    loginPage: {
        title: "Автоматизированная Система контроля и учёта газа для юридических лиц",
        rememberMe: "Запомнить меня",
        signIn: "Войти",
    },
    customersInfo: { // meter ichidagi userni infosi
        accountNumber: "Лицевой счёт:",
        customerName: "Наименование абонента:",
        customerAddress: "Адрес абонента:",
        customerPhoneNumber: "Телефонный номер абонента:",
        meterModel: "Модель счетчика:"
    },
    companyName: "Автоматизированная Система",
    companyDescription: "контроля и учёта газа для юридических лиц",
    inputTitles: {
        userName: "Наименование абонента",
        accountNumber: "Лицевой счёт",
        username: "Пользователь",
        firstName: "Имя",
        lastName: "Фамилия",
        customerAddress: "Адрес",
        customerPhoneNumber: "Телефонный номер абонента",
        imeiNumber: "IMEI код",
        password: "Создать пароль",
        users: "Пользователи",
        phone: "Телефон",
        meterModel: "Модель счетчика"
    },
    inputPlaceholders: {
        username: "Введите логин пользователя",
        login: "Логин",
        userFullName: "Введите Наименование абонента",
        password: "Пароль",
        phone: "Телефон",
        accountNumber: "000001",
        firstName: "Введите имя пользователя",
        lastName: "Введите фамилию пользователя",
        customerAddress: "Введите адрес пользователя",
        customerPhoneNumber: "Ваш номер телефона",
        imeiNumber: "IMEI код",
        searchByImei: "Поиск по IMEI",
        searchBySerialNumber: "Поиск по серийный номер",
        meterModel: "Модель счетчика"
    },
    errors: {
        signInError: "Логин или пароль неправильный",
        fieldIsEmpty: "поле не должно быть пустым",
        usernameHasAlreadyTaken: "Имя пользователья уже занято",
        imeiHasNotExist: "Этот IMEI еще не зарегистрирован в систему",
        imeiHasAlreadyTaken: "Этот IMEI уже существует",
        searchResultNotFound: "По вашему запросу ничего не найдено"
    },
    pages: {
        dashboard: "Панель",
        tableList: "Абоненты",
        users: "Пользователи"
    },
    buttons: {
        logOut: "Выйти",
        back: "Назад",
        confirm: "Да",
        refuse: "Нет",
        add: "Добавить",
        close: "Закрыть",
        save: "Сохранить",
        search: "Поиск",
        reset: "Сбросить"
    },
    table: {
        tableHeader: {
            customerFullName: "Наименование абонента",
            volume: " Приведенный объем, м",
            temperature: "Температура",
            model: "Модель",
            region: "Область",
            district: "Район",
            address: "Адрес",
            status: "Статус",
            active: "Активный",
            inActive: "Неактивный",
            meterBattery: "Заряд батареи счётчика",
            modemBattery: "Заряд батареи GSM",
            hasProblem: "Код ошибки",
            created: "Время создания",
            updated: "Время редактирования",
            serialNumber: "Серийный номер",
            errorCode: "Код ошибки",
            voltage: "Волть",
            hour: "Час",
            day: "День",
            month: "Месяц",
            year: "Год",
            userName: "Пользователь",
            firstName: "Имя",
            lastName: "Фамилия",
            phoneNumber: "Телефон",
            imei: "IMEI",
            signalLevel: "Уровень сигнала",
            lastVolumeDate: "Последний вывод отчета",
            volumeDate: "Время отчета",
            lastSeenAt: "Последний запрос на сервер"
        }
    },
    popup: {
        popupHeaderTitles: {
            logOut: "Вы точно хотите выйти?",
            infoIsNotAvailable: "Информация не доступно",
            delete: "Вы точно хотите удалить?"
        },
        meterInfoIsNotAvailable: "Информации счётчика пока пусто",
        deleteMeter: "Все данные этого счётчика будут утерянны"
    },
    pagination: {
        previous: "Предыдущий",
        next: "Следующий"
    },
    resultOfSearch: "Результаты поиска"
};