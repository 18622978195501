import axios from "axios";

const api = "https://essd.uz/api/cp";

export default class Api {
  constructor(store = null) {
    this.store = store;
  }

  // SIGN IN
  signIn = (body) => {
    return axios.post(`${api}/account/sign-in`, body);
  };
  signOut = () => {
    const {access_token} = this.store;
    return axios.get(`${api}/account/sign-out`, {
      headers: {"X-Authorization": access_token}
    })
  };

  // USERS
  usersList = (pageNumber, pageSize) => {
    const {access_token} = this.store;
    return axios.get(`${api}/users?page=${pageNumber}&offset=${pageSize}`, {
      headers: {
        "X-Authorization": access_token
      }
    })
  };
  creatingUser = (body) => {
    const {access_token} = this.store;
    return axios.post(`${api}/users/create`, body, {
      headers: {
        "X-Authorization": access_token
      }
    })
  };
  deleteUser = (id) => {
    const {access_token} = this.store;
    return axios.get(`${api}/users/delete?id=${id}`, {
      headers: {
        "X-Authorization": access_token
      }
    })
  };

  // METERS
  metersList = (pageNumber, pageSize) => {
    const {access_token} = this.store;
    return axios.get(`${api}/meters?page=${pageNumber}&offset=${pageSize}`, {
      headers: {
        "X-Authorization": access_token
      }
    })
  };
  createMeter = (body) => {
    const {access_token} = this.store;
    return axios.post(`${api}/meters/create`, body, {
      headers: {
        "X-Authorization": access_token
      }
    })
  };
  updateMeter = (body) => {
    const {access_token} = this.store;
    return axios.post(`${api}/meters/update`, body, {
      headers: {
        "X-Authorization": access_token
      }
    })
  };
  deleteMeter = (id) => {
    const {access_token} = this.store;
    return axios.get(`${api}/meters/delete?id=${id}`, {
      headers: {
        "X-Authorization": access_token
      }
    })
  };
  meterData = (id, pageNumber, pageSize) => {
    const {access_token} = this.store;
    return axios.get(`${api}/meters/indicators?meter_id=${id}&page=${pageNumber}&offset=${pageSize}`, {
      headers: {
        "X-Authorization": access_token
      }
    })
  };

  // FILTER
  filterMeterList = (pageNumber, pageSize, imei, serialNumber) => {
    const {access_token} = this.store;
    return axios.get(`${api}/meters?page=${pageNumber}&offset=${pageSize}&imei=${imei}&sn=${serialNumber}`, {
      headers: {
        "X-Authorization": access_token
      }
    })
  };
}